import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Routes as Router, Redirect, useRouteMatch } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import Navbar from '../components/Pages/Navbaar';
import SignUp from '../container/profile/authentication/overview/Signup';
const About = lazy(() => import('../components/Pages/About'));
const Blog = lazy(() => import('../components/Pages/Blog'));
const Services = lazy(() => import('../components/Pages/services'));
const Contact = lazy(() => import('../components/Pages/Contact'));
const Portfolio = lazy(() => import('../components/Pages/Portfolio'));
const Pricing = lazy(() => import('../components/Pages/Pricing'));
const Pickup = lazy(() => import('../components/Pages/Pickup'));
const Insurance = lazy(() => import('../components/Pages/Insurance'));
const Edit_Profile = lazy(() => import('../components/Pages/Edit_Profile'));
const Notification = lazy(() => import('../components/Pages/Notification'));
const Order_history = lazy(() => import('../components/Pages/Order_history'));
const Subscription = lazy(() => import('../components/Pages/Subscription'));
const Checkout_new = lazy(() => import('../components/Pages/Checkout_new'));
const Account_details = lazy(() => import('../components/Pages/Account_details'));
const HomepageNew = lazy(() => import('../container/pages/HomepageNew'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const OneTimePass = lazy(() => import('../container/profile/authentication/overview/OneTimePassword'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const Category = lazy(() => import('../components/Pages/Category'));
const Cart = lazy(() => import('../components/Pages/Cart'));
const Login = lazy(() => import('../container/profile/authentication/overview/Login'));
const SignIn = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const VerifiedAccount = lazy(() => import('../container/profile/authentication/overview/VerifiedAccount'));

const NotFound = () => {
  console.log('not found');
  return <Redirect to="/" />;
};

const FrontendRoutes = () => {
  console.log('frontend route');
  const { path } = useRouteMatch();
  return (
    <>
      {/* <Navbar /> */}
      <Switch>
        <Suspense fallback={<div id="cover-spin"></div>}>
          {/* <Route exact path={path} component={Dashboard} /> */}
          {/* <Route path={`${path}/about`} component={About} />
          <Route path={`${path}/blog`} component={Blog} />
          <Route path={`${path}/services`} component={Services} />
          <Route path={`${path}/contact`} component={Contact} />
          <Route path={`${path}/portfolio`} component={Portfolio} />
          <Route path={`${path}/pricing`} component={Pricing} /> */}

          <Route exact path="/about" component={About} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/Checkout/:id" component={Checkout_new} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/Order_history" component={Order_history} />
          <Route exact path="/Subscription" component={Subscription} />
          <Route exact path="/pickup" component={Pickup} />
          <Route exact path="/Insurance" component={Insurance} />
          <Route exact path="/Edit_Profile" component={Edit_Profile} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/Account_details" component={Account_details} />
          <Route exact path="/verifiedaccount" component={VerifiedAccount} />

          <Route exact path="/category/:id" component={Category} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/register" component={SignUp} />
          <Route exact path="/forgotPassword" component={ForgotPass} />
          <Route exact path="/oneTimePassword" component={OneTimePass} />
          <Route exact path="/resetPassword/:id" component={ResetPass} />
          <Route exact path="/" component={HomepageNew} />
          {/* <Route exact path="/exam/:id" component={Exam} />
        <Route exact path="/practice-sets/:id" component={PracticeSet} />
        <Route exact path="/quiz/:id" component={Quiz} />
        <Route exact path="/exam-success" component={ExamSuccess} />
        <Route exact path="/quiz-success" component={QuizSuccess} />
        <Route exact path="/practice-sets-success" component={PracticeSuccess} />
        <Route exact path="/" component={Home} />
        <Route exact path="/Analysis" component={Analysis} />
        <Route exact path="/viewResults" component={ViewResults} />
        <Route exact path="/overview-quiz/:id" component={OverViewQuiz} />
        <Route exact path="/overview-exam/:id" component={OverViewExam} />
        <Route exact path="/overview-practice-set/:id" component={OverViewPractice} /> */}
        </Suspense>
      </Switch>
    </>
  );
};

export default AuthLayout(FrontendRoutes);
