import { Link, NavLink, useHistory, useParams, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect } from 'react';
import { Row, Col, Avatar } from 'antd';
import login from '../../img/login.png';
import Logo from '../../img/white-logo.png';
import UserImagee from '../../img/customer4.jpg';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { api_url, get_api_request } from '../../helpers/Api';
import { headers } from '../../helpers/variables';
import { decrypt } from '../../helpers/encryption-decryption';
import { signOut } from '../../redux/authentication/actionCreator';
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const Navbaar = () => {
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const [ishemBurger, sethemBurger] = useState(false);
  const [Home, setHome] = useState(false);
  const [Pricing, setPricing] = useState(false);
  const [Blogs, setBlogs] = useState(false);
  const [About, setAbout] = useState(false);
  const [Contact, setContact] = useState(false);
  const [Services, setServices] = useState(false);
  const [CartData, setCartData] = useState([]);
  const enc_user_detail = Cookies.get('UserDetail');
  const UserData = enc_user_detail ? decrypt(enc_user_detail) : '';
  const uid = UserData?.sessdata?.id;
  const access_token = UserData?.sessdata?.user?.[0]?.accesstoken;
  const UserImage = UserData?.sessdata?.user?.[0]?.image;

  useEffect(() => {
    GetCartByUserID();
    if (
      window.location.href == 'https://citygreenlaundry.com/' ||
      window.location.href == 'http://citygreenlaundry.com/' ||
      window.location.href == 'http://localhost:3004/'
    ) {
      setHome(true);
      setAbout(false);
      setPricing(false);
      setServices(false);
      setBlogs(false);
      setContact(false);
    } else if (
      window.location.href == 'https://citygreenlaundry.com/about' ||
      window.location.href == 'http://citygreenlaundry.com/about' ||
      window.location.href == 'http://localhost:3004/about'
    ) {
      setHome(false);
      setAbout(true);
      setPricing(false);
      setServices(false);
      setBlogs(false);
      setContact(false);
    } else if (
      window.location.href == 'https://citygreenlaundry.com/pricing' ||
      window.location.href == 'http://citygreenlaundry.com/pricing' ||
      window.location.href == 'http://localhost:3004/pricing'
    ) {
      setHome(false);
      setAbout(false);
      setPricing(true);
      setServices(false);
      setBlogs(false);
      setContact(false);
    } else if (
      window.location.href == 'https://citygreenlaundry.com/services' ||
      window.location.href == 'http://citygreenlaundry.com/services' ||
      window.location.href == 'http://localhost:3004/services'
    ) {
      setHome(false);
      setAbout(false);
      setPricing(false);
      setServices(true);
      setBlogs(false);
      setContact(false);
    } else if (
      window.location.href == 'https://citygreenlaundry.com/blog' ||
      window.location.href == 'http://citygreenlaundry.com/blog' ||
      window.location.href == 'http://localhost:3004/blog'
    ) {
      setHome(false);
      setAbout(false);
      setPricing(false);
      setServices(false);
      setBlogs(true);
      setContact(false);
    } else if (
      window.location.href == 'https://citygreenlaundry.com/contact' ||
      window.location.href == 'http://citygreenlaundry.com/contact' ||
      window.location.href == 'http://localhost:3004/contact'
    ) {
      setHome(false);
      setAbout(false);
      setPricing(false);
      setServices(false);
      setBlogs(false);
      setContact(true);
    }
  }, []);
  async function GetCartByUserID() {
    const url = api_url?.cart + uid;
    const response = await get_api_request(url, headers);
    if (response?.status == 201) {
      const cart_data = response?.data?.cart_data?.[0]?.cart_data?.cart;
      setCartData(cart_data);
      const elm = document.getElementById('shooping-bag');
      if (elm) {
        elm.innerHTML = cart_data?.length;
      }
    }
  }

  const navToggle = event => {
    setIsActive(current => !current);
  };

  const hemBurger = event => {
    sethemBurger(current => !current);
  };

  const RedirectToDashboard = () => {
    history.push(`/pickup`);
    window.location.reload();
  };

  const RedirectToCart = () => {
    var CartDetails = [];
    async function GetAllProductCategory() {
      //getall_product_categorys
      const url = api_url?.getall_product_categorys;
      const response = await get_api_request(url, headers);
      if (response?.status == 200) {
        const category_data = response?.data?.responsedata?.productcategories;
        const cart_url = api_url?.cart + uid;
        const cart_response = await get_api_request(cart_url, headers);
        if (cart_response?.status == 201) {
          const cart_data = cart_response?.data?.cart_data?.[0]?.cart_data?.cart;
          category_data?.map((category, index) => {
            category?.product_data?.map((product, i) => {
              cart_data?.map(item => {
                if (product?.id == item?.product_id) {
                  category.product_data[i].cart_count = item?.quantity;
                  var data = category.product_data[i];
                  CartDetails.push(data);
                }
              });
            });
          });
          history.push({
            pathname: '/cart',
            state: CartDetails ? CartDetails : '',
          });
          window.location.reload();
        }
      }
    }
    GetAllProductCategory();
  };
  return (
    <>
      <Row>
        <div className="container wid_1">
          <Col md={24}>
            <div className="container navbaar m_auto">
              <div className="logo">
                <a href="/">
                  <img src={Logo} />
                </a>
              </div>
              <div className={isActive ? 'hideRemove nav_v' : 'nav_v'}>
                <ul>
                  <li>
                    <a
                      className={Home == true ? 'activehovermenu' : ''}
                      href="/"
                      onClick={() => {
                        setHome(true);
                        setPricing(false);
                        setBlogs(false);
                        setDEMOS(false);
                        setTRIAL(false);
                        setContact(false);
                        // handleNavBar()
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className={About == true ? 'activehovermenu' : ''}
                      href="/about"
                      onClick={() => {
                        setHome(false);
                        setAbout(true);
                        setServices(false);
                        setPricing(false);
                        setBlogs(false);
                        setContact(false);
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className={Services == true ? 'activehovermenu' : ''}
                      href="/services"
                      onClick={() => {
                        setHome(false);
                        setAbout(false);
                        setServices(true);
                        setPricing(false);
                        setBlogs(false);
                        setContact(false);
                      }}
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={Pricing == true ? 'activehovermenu' : ''}
                      href="/pricing"
                      onClick={() => {
                        setHome(false);
                        setAbout(false);
                        setServices(false);
                        setPricing(true);
                        setBlogs(false);
                        setContact(false);
                      }}
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      className={Blogs == true ? 'activehovermenu' : ''}
                      href="/blog"
                      onClick={() => {
                        setHome(false);
                        setAbout(false);
                        setServices(false);
                        setPricing(false);
                        setBlogs(true);
                        setContact(false);
                      }}
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className={Contact == true ? 'activehovermenu' : ''}
                      onClick={() => {
                        setHome(false);
                        setAbout(false);
                        setServices(false);
                        setPricing(false);
                        setBlogs(false);
                        setContact(true);
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                  {access_token ? (
                    <li>
                      <div className="dropdown">
                        <button className="dropbtn" id="showdiv">
                          {/* <img
                            src={'/customer4.jpg'}
                            className="login_icon"
                            style={{ cursor: 'pointer', marginLeft: '0px' }}
                          /> */}
                          <Avatar size="large" src={<img src={UserImagee} alt="avatar" />} />
                        </button>
                        <div className="dropdown-content">
                          <a>
                            <FeatherIcon icon="user" style={{ marginRight: '5px' }} className="myaccdiv" />
                            Account Details
                          </a>

                          {/* <a>
                            <FeatherIcon icon="list" style={{ marginRight: '5px' }} className="myaccdiv" />
                            Listings
                          </a> */}
                          <Link
                            onClick={e => {
                              signOut(e);
                            }}
                          >
                            <FeatherIcon icon="user-minus" style={{ marginRight: '5px' }} />
                            Sign out
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li onClick={() => history.push(`/login`)}>
                      <img src={login} className="login_icon" style={{ cursor: 'pointer' }} />
                    </li>
                  )}
                  <li
                    //onClick={() => history.push(`/account_details`)}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      RedirectToCart();
                      //CartData?.length > 0 ? (window.location.href = '/cart') : '';
                    }}
                  >
                    <li>
                      {CartData != undefined ? <div className="cart_count" id="shooping-bag"></div> : ''}
                      <FeatherIcon size={16} icon="shopping-bag" className="shooping-bag" />
                    </li>
                  </li>
                  <div className="m_1">
                    {enc_user_detail ? (
                      <NavLink className="btn_1" to="#" onClick={RedirectToDashboard}>
                        Schedule a Pickup
                      </NavLink>
                    ) : (
                      <NavLink className="btn_1" to="/pickup">
                        Schedule a Pickup
                      </NavLink>
                    )}
                  </div>
                </ul>
              </div>
              <a className="icon" onClick={navToggle}>
                <div id="nav-icon3" onClick={hemBurger} className={isActive ? 'open' : ''}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default Navbaar;
