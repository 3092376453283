import Axios from 'axios';
import { headers } from './variables';
import Cookies from 'js-cookie';
import { decrypt } from './encryption-decryption';
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const api_url = [];

/*+++++++++++++++MPI+++++++++++++++++++++++++STARTS */

/*Global Auth */
api_url['global_auth_url'] = '/global/auth/url';

/*Subscription */
api_url['subscriptionplans'] = '/subscription/plan/';
api_url['subscriptionplansdelete'] = '/subscription/plan/';
/*UserList */
api_url['facebook'] = '/facebook/';
api_url['facebook_by_user'] = '/facebook/user/';
api_url['facebook_accesstoken'] = '/facebook/accesstoken';

api_url['linkedin'] = '/linkedin/';
api_url['linkedin_by_user'] = '/linkedin/user/';
api_url['linkedin_accesstoken'] = '/linkedin/accesstoken';

api_url['instagram'] = '/instagram/';
api_url['instagram_by_user'] = '/instagram/user/';
api_url['instagram_accesstoken'] = '/instagram/accesstoken';

api_url['twitter'] = '/twitter/';
api_url['twitter_by_user'] = '/twitter/user/';
api_url['twitter_accesstoken'] = '/twitter/accesstoken';

/*Post */
//api_url['create_post'] = '/post/';
api_url['create_post'] = '/post/';
api_url['get_all_posts'] = '/post/';
api_url['get_posts_byid'] = '/post/user/';
api_url['trash_posts_byid'] = '/post/trash/';

/*Customer */
api_url['create_user'] = '/user/';

api_url['get_all_users'] = '/user/';
api_url['trash_user'] = '/user/trash/';

/* UpdatePassword */
api_url['UpdatePassword'] = '/user/passwordupdate/';

/* Images */

api_url['url'] = '/images/post';
api_url['verifyotp'] = '/user/verifyotp/';
api_url['forgot_password'] = '/user/password/forgot';
/*+++++++++++++++MPI+++++++++++++++++++++++++ENDS */

/*UserList */
api_url['user'] = '/user/admin/';
api_url['delete_user'] = '/user/';

const get_rolesby_store = id => {
  api_url['get_rolesby_store'] = `/store/${id}/role/`;
  return api_url['get_rolesby_store'];
};

/*Edit Category */

/*Store Modules */
api_url['get_modulesingle_byID'] = '/module/single/';
api_url['get_allmodules'] = '/module/all';
api_url['module_checkmodulealready'] = '/module/checkmodulealready';
api_url['update_module'] = '/module/';

/*Store Discount */
api_url['get_storesall'] = '/store/all';
api_url['discount_checkalready'] = '/discount/checkalready';
api_url['create_discount'] = '/discount/';
api_url['get_all_users'] = '/user/admin';
/*Store Discount */
api_url['customer_delete'] = '/customer/delete/';
//

/**LaundryAPP+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS **/
api_url['getall_categories'] = '/productcategory/all';
api_url['get_productcategory_single'] = '/productcategory/single/';
api_url['parent_categories'] = '/productcategory/allNamesAndIds';
api_url['create_categories'] = '/productcategory/';
api_url['update_categories'] = '/productcategory/update/';
api_url['getall_product_categorys'] = '/productcategory/product/all';
api_url['getproduct_bycategoryid'] = '/productcategory/single/';
api_url['getcategory_byslug'] = '/productcategory/slug/';

api_url['get_subscription_type'] = '/subscription/type/';
api_url['get_subscription_tenure'] = '/subscription/tenure/';
api_url['create_subscription_plan'] = '/subscription/plan';
api_url['get_subscription_plan_byid'] = '/subscription/plan/';
api_url['update_subscription_plan_byid'] = '/subscription/plan/';

api_url['all_roles'] = '/role/get/all';
api_url['create_user'] = '/user/';
api_url['view_user'] = '/user/';

api_url['get_single_product'] = '/products/single/';
api_url['get_all_product'] = '/products/all';
api_url['create_product'] = '/products/';
api_url['edit_user'] = '/user/';

api_url['subscription_plans'] = '/subscription/plan/allNamesAndIds';
api_url['get_subscription_userByID'] = '/subscription/user/';
api_url['edit_user'] = '/user/';

// customers
api_url['get_all_customers'] = '/customer/';
api_url['customer_Create'] = '/customer/';

api_url['orders_all'] = '/orders/all';

api_url['get_all_role'] = '/role/allNamesAndIds/all';

// Pages
api_url['all_pages'] = '/page/all';
api_url['get_pages'] = '/page/';
api_url['create_pages'] = '/page/';

//FAQ
api_url['faq_all'] = '/faq/all';
api_url['get_faq'] = '/faq/';

api_url['create_banner'] = '/banner/';
api_url['get_all_banner'] = '/banner/all';
api_url['get_banner_id'] = '/banner/single/';
api_url['update_banner'] = '/banner/update/';
api_url['delete_banner'] = '/banner/';
api_url['get_customer_address'] = '/address/';
api_url['get_allcountry'] = '/country/';
api_url['cart'] = '/cart/';

/*Request Function Starts +++++*/
const get_api_request = (url, headers = null) => {
  // console.log(url);
  const promise1 = new Promise((resolve, reject) => {
    Axios.get(domainpath + url, { headers })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      //console.log(value);
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
//--------------------------------------------------------
const post_api_request = (url, payload, headers = null) => {
  // Axios.post(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  const promise1 = new Promise((resolve, reject) => {
    Axios.post(domainpath + url, payload, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
//---------------------------------------------------------
const put_api_request = (url, payload, headers = null) => {
  // Axios.put(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  const promise1 = new Promise((resolve, reject) => {
    Axios.put(domainpath + url, payload, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      var message = '';
      if (error?.message?.response?.data?.message) {
        message = error.message.response.data.message;
      } else {
        message = error;
      }
      const Error = {
        status: 'error',
        message: message,
      };
      return Error;
    });
  return response_promise;
};
//----------------------------------------------------------
const delete_api_request = (url, headers = null) => {
  // Axios.delete(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  //console.log(url);
  const promise1 = new Promise((resolve, reject) => {
    Axios.delete(domainpath + url, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
/*++++++++Get Module Permissions From Cookies +++++++++++++++++STARTS*/
const ModulePermissions = ModuleName => {
  var ModulePermissions = {};
  var getModule = [];

  async function GetUserDetail(ModuleName) {
    const enc_user_detail = Cookies.get('UserDetail');
    var userDetail = decrypt(enc_user_detail);
    var data = userDetail?.sessdata?.user?.[0];

    //get User Role dynamicaly ----
    var UserRole = data?.user_role.toUpperCase();

    //get Modules dynamicaly ----
    var currentStoreModules = data?.permissions?.[UserRole].MODULES;
    var Modules_key = Object.keys(currentStoreModules); // to check is modules available or not?
    Modules_key?.map((item, value) => {
      if (item == ModuleName) {
        return (getModule = item);
      }
    });
    //------------ Make array for all modules------------start

    var modules_array = Object.entries(currentStoreModules).map(([key, value]) => ({ key, value }));

    modules_array?.map(item => {
      if (item.key == ModuleName) {
        ModulePermissions = { item: item, role: UserRole };
        // const perminssion_values = item.value.split(','); // get module value
        // ModulePermissions['view'] = perminssion_values[0];
        // ModulePermissions['add'] = perminssion_values[1];
        // ModulePermissions['edit'] = perminssion_values[2];
        // ModulePermissions['delete'] = perminssion_values[3];
      }
    });

    //---------------make array for all modules---------------end
  }
  GetUserDetail(ModuleName);
  return ModulePermissions;
};
/*++++++++Get Module Permissions From Cookies +++++++++++++++++ENDS*/
export {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  get_rolesby_store,
  api_url,
  ModulePermissions,
};
